.experience-component .row-links {
  display: flex;
  justify-content: center; }

.experience-component .anchor-link,
.experience-component .nav-link {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-weight: 600;
  font-size: rem(18);
  color: #000;
  text-align: center;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease; }
  .experience-component .anchor-link picture,
  .experience-component .nav-link picture {
    width: 100%; }
  .experience-component .anchor-link .anchor-title,
  .experience-component .anchor-link .nav-title,
  .experience-component .nav-link .anchor-title,
  .experience-component .nav-link .nav-title {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    margin: 1rem 0;
    border-bottom: 1px solid transparent;
    transition: border-bottom ease 0.3s; }
  .experience-component .anchor-link:hover, .experience-component .anchor-link.active,
  .experience-component .nav-link:hover,
  .experience-component .nav-link.active {
    color: #0070d2; }
    .experience-component .anchor-link:hover .nav-title, .experience-component .anchor-link.active .nav-title,
    .experience-component .nav-link:hover .nav-title,
    .experience-component .nav-link.active .nav-title {
      border-bottom: 1px solid #0070d2; }
  .experience-component .anchor-link.active,
  .experience-component .nav-link.active {
    pointer-events: none; }
  .experience-component .anchor-link.no-image,
  .experience-component .nav-link.no-image {
    text-transform: none;
    padding: 0.5rem; }
    @media (min-width: 769px) {
      .experience-component .anchor-link.no-image,
      .experience-component .nav-link.no-image {
        padding: 1.5rem 1rem; } }
    .experience-component .anchor-link.no-image .anchor-title,
    .experience-component .anchor-link.no-image .nav-title,
    .experience-component .nav-link.no-image .anchor-title,
    .experience-component .nav-link.no-image .nav-title {
      margin: 0; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.experience-component .nav-row-outer.has-border {
  border-top: 1px solid var(--color-grey200);
  border-bottom: 1px solid var(--color-grey200); }

@media (min-width: 768px) {
  .experience-component .row-links {
    justify-content: center; }
    .experience-component .row-links.links-left {
      justify-content: flex-start; }
    .experience-component .row-links.links-right {
      justify-content: flex-end; } }

.experience-component .nav-row.light .anchor-link,
.experience-component .nav-row.light .nav-link {
  color: var(--color-white); }

.experience-component .nav-row .mobile-swipe.slick-initialized {
  display: block; }
  .experience-component .nav-row .mobile-swipe.slick-initialized .slick-list {
    overflow: visible; }
  .experience-component .nav-row .mobile-swipe.slick-initialized .slick-slide {
    height: auto; }

.experience-component .anchor-link,
.experience-component .nav-link {
  text-decoration: none; }
  .experience-component .anchor-link picture,
  .experience-component .nav-link picture {
    display: flex;
    justify-content: center;
    align-items: center; }
    .experience-component .anchor-link picture img,
    .experience-component .nav-link picture img {
      display: block; }
  .experience-component .anchor-link .anchor-title,
  .experience-component .anchor-link .nav-title,
  .experience-component .nav-link .anchor-title,
  .experience-component .nav-link .nav-title {
    border-bottom: 0;
    transition: none;
    font-family: "Neue Haas Grotesk Text Round", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-decoration: none; }
  .experience-component .anchor-link:hover, .experience-component .anchor-link.active,
  .experience-component .nav-link:hover,
  .experience-component .nav-link.active {
    color: var(--color-black); }
    .experience-component .anchor-link:hover .nav-title, .experience-component .anchor-link.active .nav-title,
    .experience-component .nav-link:hover .nav-title,
    .experience-component .nav-link.active .nav-title {
      border-bottom: 0; }
  .experience-component .anchor-link:hover .nav-title,
  .experience-component .nav-link:hover .nav-title {
    position: relative; }
    .experience-component .anchor-link:hover .nav-title:after,
    .experience-component .nav-link:hover .nav-title:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.5rem;
      height: 0.125rem;
      width: 100%;
      background-color: var(--color-black); }
  .experience-component .anchor-link.active .nav-title,
  .experience-component .nav-link.active .nav-title {
    font-family: "Neue Haas Grotesk Text Round Bold", sans-serif;
    font-weight: 700;
    position: relative; }
    .experience-component .anchor-link.active .nav-title:after,
    .experience-component .nav-link.active .nav-title:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.5rem;
      height: 0.125rem;
      width: 100%;
      background-color: var(--color-accent); }
  .experience-component .anchor-link.no-image,
  .experience-component .nav-link.no-image {
    padding: 1.8125rem 1.5625rem; }
    @media (min-width: 768px) {
      .experience-component .anchor-link.no-image,
      .experience-component .nav-link.no-image {
        padding: 1.8125rem 2.375rem; } }

.experience-component .nav-block {
  padding: 0; }
  .experience-component .nav-block .headline-row {
    margin-bottom: 1rem; }
  .experience-component .nav-block .nav-block-toplink {
    text-decoration: none;
    font-family: "Neue Haas Grotesk Text Round Bold", sans-serif;
    font-weight: 700; }
  .experience-component .nav-block .tile-wrap {
    padding: 0 0.8125rem;
    margin-bottom: 1.625rem; }
  .experience-component .nav-block .nav-link {
    padding: 1rem 0; }
    @media (min-width: 544px) {
      .experience-component .nav-block .nav-link {
        padding: 1rem; } }
    .experience-component .nav-block .nav-link picture {
      margin: 1rem 0 0; }
      @media (min-width: 544px) {
        .experience-component .nav-block .nav-link picture {
          margin: 1rem; } }
      @media (min-width: 992px) {
        .experience-component .nav-block .nav-link picture {
          min-height: 14.5rem; } }
    .experience-component .nav-block .nav-link .nav-title {
      font-size: 1.25rem; }
    .experience-component .nav-block .nav-link:hover {
      text-decoration: underline; }
      .experience-component .nav-block .nav-link:hover .nav-title:after {
        display: none; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.experience-component .nav-row-outer.has-border {
  border-top: 1px solid var(--color-grey200);
  border-bottom: 1px solid var(--color-grey200); }

.experience-component .nav-row-outer .equal-columns > * {
  flex: 1; }

@media (min-width: 769px) {
  .experience-component .row-links {
    justify-content: center; }
    .experience-component .row-links.links-left {
      justify-content: flex-start; }
    .experience-component .row-links.links-right {
      justify-content: flex-end; } }

.experience-component .nav-row.light .anchor-link,
.experience-component .nav-row.light .nav-link {
  color: var(--color-white); }

.experience-component .nav-row .mobile-swipe.slick-initialized {
  display: block; }
  .experience-component .nav-row .mobile-swipe.slick-initialized .slick-list {
    overflow: visible; }
  .experience-component .nav-row .mobile-swipe.slick-initialized .slick-slide {
    height: auto; }

.experience-component .anchor-link,
.experience-component .nav-link {
  text-decoration: none; }
  .experience-component .anchor-link picture,
  .experience-component .nav-link picture {
    display: flex;
    justify-content: center;
    align-items: center; }
    .experience-component .anchor-link picture img,
    .experience-component .nav-link picture img {
      display: block; }
  .experience-component .anchor-link .anchor-title,
  .experience-component .anchor-link .nav-title,
  .experience-component .nav-link .anchor-title,
  .experience-component .nav-link .nav-title {
    border-bottom: 0;
    transition: none;
    font-family: "SuisseIntl-Medium", sans-serif;
    font-display: swap;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-decoration: none; }
  .experience-component .anchor-link:hover, .experience-component .anchor-link.active,
  .experience-component .nav-link:hover,
  .experience-component .nav-link.active {
    color: var(--color-black); }
    .experience-component .anchor-link:hover .nav-title, .experience-component .anchor-link.active .nav-title,
    .experience-component .nav-link:hover .nav-title,
    .experience-component .nav-link.active .nav-title {
      border-bottom: 0; }
  .experience-component .anchor-link:hover .nav-title,
  .experience-component .nav-link:hover .nav-title {
    position: relative; }
    .experience-component .anchor-link:hover .nav-title:after,
    .experience-component .nav-link:hover .nav-title:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.5rem;
      height: 0.125rem;
      width: 100%;
      background-color: var(--color-black); }
  .experience-component .anchor-link.active .nav-title,
  .experience-component .nav-link.active .nav-title {
    font-family: "SuisseIntl-SemiBold", sans-serif;
    font-display: swap;
    position: relative; }
    .experience-component .anchor-link.active .nav-title:after,
    .experience-component .nav-link.active .nav-title:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.5rem;
      height: 0.125rem;
      width: 100%;
      background-color: var(--color-accent); }
  .experience-component .anchor-link.no-image,
  .experience-component .nav-link.no-image {
    padding: 1.8125rem 1.5625rem; }
    @media (min-width: 769px) {
      .experience-component .anchor-link.no-image,
      .experience-component .nav-link.no-image {
        padding: 1.8125rem 2.375rem; } }

.experience-component .nav-block .headline-row {
  margin-bottom: 1rem; }

.experience-component .nav-block .nav-block-toplink {
  text-decoration: none;
  font-family: "SuisseIntl-SemiBold", sans-serif;
  font-display: swap; }

.experience-component .nav-block .tile-wrap {
  padding: 0 0.8125rem;
  margin-bottom: 1.625rem; }

.experience-component .nav-block .nav-link {
  padding: 1rem; }
  .experience-component .nav-block .nav-link picture {
    margin: 1rem; }
    @media (min-width: 992px) {
      .experience-component .nav-block .nav-link picture {
        min-height: 14.5rem; } }
  .experience-component .nav-block .nav-link .nav-title {
    font-size: 1.25rem; }
  .experience-component .nav-block .nav-link:hover .nav-title:after {
    display: none; }

.experience-component .carousel .nav-link {
  justify-content: left;
  text-align: left;
  padding: 0.5rem 0.5rem; }
  .experience-component .carousel .nav-link picture {
    overflow: hidden; }
    .experience-component .carousel .nav-link picture img:hover {
      transform: scale(1.3);
      transition: 1s; }

.experience-component .carousel:hover .nav-link.no-image .nav-title::after {
  width: 100%; }

.experience-component .carousel:hover .nav-link .nav-title::after {
  width: 0; }
